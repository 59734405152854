export * from './photoProducts'
export * from './photoProductsCart'

export type CuratedPhoto = {
  height: number
  tookAt: string
  url: string
  uuid: string
  width: number
}

export type CuratedPhotoForPhotoProducts = {
  mediumId: string
  croppedUrl: string
  url: string
  x: number
  y: number
  width: number
  height: number
}

export type CropDefinition = {
  x: number
  y: number
  width: number
  height: number
}

export enum VariantCellPositionRelativeToTemplate {
  OnTop = 'OnTop',
  Beneath = 'Beneath',
}

export enum CellType {
  Image = 'Image',
  PatternImage = 'PatternImage',
}

export type Cell = {
  id: number
  absoluteCenterX: number
  absoluteCenterY: number
  width: number
  height: number
  order: number
  zIndex: number
  positionRelativeToTemplate: VariantCellPositionRelativeToTemplate
  type: CellType.Image | CellType.PatternImage
  imageData: { id: number; rotationAngle: number } | null
  templateVariantId: number
  patternData: {
    matrix: number[][]
  } | null
  shape?: 'rectangle' | 'circle'
}

export type DesignTemplate = {
  id: number
  name: string
  productVariantIds: number[]
  maskImageUrl: string
  numberOfUserImages: number
  cells: Cell[]
  thumbnailUrl: string
}
