import { useLocation } from 'react-router-dom'
import { type ApiClient } from '@core/api/client'
import { useCallback } from 'react'

enum UserActions {
  DID_VIEW_POD_LP = 'DidViewPodLp',
  DID_TAP_POD_C2A = 'DidTapPodC2A',
  DID_VIEW_POD_STICKERS_LP = 'DidViewPodStickersLp',
  DID_TAP_POD_STICKERS_C2A = 'DidTapPodStickersC2A',
  DID_VIEW_POD_STICKERS_SHEET_LP = 'DidViewPodStickersSheetLp',
  DID_TAP_POD_STICKERS_SHEET_C2A = 'DidTapPodStickersSheetC2A',
  DID_SELECT_POD_CATEGORY = 'DidSelectPodCategory',
  DID_SELECT_POD_PRODUCT = 'DidSelectPodProduct',
  DID_SELECT_POD_TEMPLATE = 'DidSelectPodTemplate',
  DID_TAP_PRODUCT_PAGE_C2A = 'DidTapProductPageC2A',
  DID_SKIP_PREVIEW = 'DidSkipPodPreview',
  DID_SELECT_PHOTOS = 'DidSelectPhotos',
  DID_VIEW_SELECT_TEMPLATE_PAGE = 'DidViewSelectTemplatePage',
  DID_TAP_CART_KEEP_SHOPPING = 'DidTapCartKeepShopping',
  DID_TAP_LOOP_C2A = 'DidTapLoopC2A',
  DID_COPY_LOOP_PROMO_CODE = 'DidCopyLoopPromoCode',
  DID_CLICK_SMOKESCREEN_C2A = 'DidClickSmokescreenC2A',
  DID_TAP_LOOP_STORE_BUY_NOW = 'DidTapLoopStoreBuyNow',
  DID_ADD_LOOP_PRODUCT_TO_CART = 'DidAddLoopProductToCart',
  DID_REACH_LOOP_CHECKOUT = 'DidReachLoopCheckout',
  DID_CREATE_LOOP_CART = 'DidCreateLoopCart',
  DID_VIEW_LOOP_LP = 'DidViewLoopLp',
  DID_TAP_SOCKS_C2A = 'DidTapSocksC2A',
  DID_COPY_SOCKS_PROMO_CODE = 'DidCopySocksPromoCode',
  DID_TAP_EGIFTS_C2A = 'DidTapEGiftsC2A',
  DID_TAP_C2A = 'DidTapC2A',
  DID_SELECT_PHOTO_PRODUCT = 'DidSelectPhotoProduct', // legacy, for old divvup/pod combined page
  DID_TAP_PREVIEW = 'DidTapPreviewPhotoProduct',
  DID_TAP_SHOPPING_CART = 'DidTapShoppingCart', // should be tapping the icon, add the number of items in the cart
  DID_ADD_PRODUCT_TO_CART = 'DidAddProductToCart', // When the user adds a product to the cart, add numOfProducts in cart for the toal after adding
  DID_TAP_TO_GET_TO_CHECKOUT = 'DidTapToGetToCheckout', // Add the number of items in cart
  DID_COMPLETE_CHECKOUT = 'DidCompleteCheckout',
  POD_PREVIEW_SUCCESS = 'PodPreviewSuccess',
  POD_PREVIEW_FAILURE = 'PodPreviewFailure',
  DID_RESUME_CART = 'DidResumeCart',
  DID_CLICK_FMN_PRODUCT = 'DidClickFmnProduct',
  TIME_SPENT_FMN_OFFER_WALL = 'TimeSpentFmnOfferWall',
  DID_COPY_FMN_PROMO_CODE = 'DidCopyFmnPromoCode',
  DID_TAP_FMN_C2A = 'DidTapFmnC2A',
  DID_TAP_FMN_SEE_ALL = 'DidTapFmnSeeAll',
}

export type PhotoSource = 'family-album' | 'device'

type TrackingParams = {
  clickedElement?: string
  providerProductId?: number
  productName?: string
  productPrintProvider?: string
  slot?: string
  numberOfPhotos?: number
  numberOfPhotosReady?: number
  numberOfProductsInCart?: number
  cartId?: number
  skippedPodSubCategory?: boolean
  pageVariant?: string
  isShowingUserPhotos?: boolean
  podCategory?: string
  productVariantId?: number
  podTemplateId?: number | string
  podTemplatePosition?: number
  photoSource?: PhotoSource
  photosSelected?: number
  photosRequired?: number
}

export type C2AMetadata = TrackingParams

const useRecordUserAction = (api: ApiClient, session: object) => {
  const location = useLocation()
  const recordUserAction = useCallback(
    (action: string, additionalMetadata: object = {}) => {
      const metadata = Object.assign(
        {
          pathname: location.pathname,
          url: window.location.href,
        },
        additionalMetadata,
        session
      )
      return api.logUserAction(action, metadata)
    },
    [api, location.pathname, session]
  )

  return {
    recordLoopC2ATap: (metadata?: TrackingParams) =>
      recordUserAction(UserActions.DID_TAP_LOOP_C2A, metadata || {}),
    recordDidCopyLoopPromoCodeTap: () => recordUserAction(UserActions.DID_COPY_LOOP_PROMO_CODE, {}),
    recordSmokeScreenC2ATap: (smokescreen: string) =>
      recordUserAction(UserActions.DID_CLICK_SMOKESCREEN_C2A, {
        smokescreen,
      }),
    recordDidTapLoopStoreBuyNow: () => recordUserAction(UserActions.DID_TAP_LOOP_STORE_BUY_NOW),
    recordDidAddLoopProductToCart: (productHandle: string, variantTitle: string) =>
      recordUserAction(UserActions.DID_ADD_LOOP_PRODUCT_TO_CART, {
        productHandle,
        variantTitle,
      }),
    recordDidReachLoopCheckout: () => recordUserAction(UserActions.DID_REACH_LOOP_CHECKOUT),
    recordDidCreateLoopCart: (cartId: string) =>
      recordUserAction(UserActions.DID_CREATE_LOOP_CART, {
        cartId,
      }),
    recordDidViewLoopLp: (metadata: TrackingParams) =>
      recordUserAction(UserActions.DID_VIEW_LOOP_LP, metadata),
    recordDidTapSocksC2A: (metadata: TrackingParams) =>
      recordUserAction(UserActions.DID_TAP_SOCKS_C2A, metadata),
    recordDidCopySocksPromoCodeTap: () => recordUserAction(UserActions.DID_COPY_SOCKS_PROMO_CODE),
    recordDidTapEGiftsC2A: (metadata: TrackingParams) =>
      recordUserAction(UserActions.DID_TAP_EGIFTS_C2A, metadata),
    recordDidTapC2A: (metadata: TrackingParams) =>
      recordUserAction(UserActions.DID_TAP_C2A, metadata),

    photoProducts: {
      recordDidSkipPreview: (metadata: TrackingParams) =>
        recordUserAction(UserActions.DID_SKIP_PREVIEW, metadata),
      recordDidViewPodLp: (metadata: TrackingParams) =>
        recordUserAction(UserActions.DID_VIEW_POD_LP, metadata),
      recordDidViewPodStickersLp: (metadata: TrackingParams) =>
        recordUserAction(UserActions.DID_VIEW_POD_STICKERS_LP, metadata),
      recordDidViewPodStickersSheetLp: (metadata: TrackingParams) =>
        recordUserAction(UserActions.DID_VIEW_POD_STICKERS_SHEET_LP, metadata),
      recordDidTapPodC2A: (metadata: TrackingParams) =>
        recordUserAction(UserActions.DID_TAP_POD_C2A, metadata),
      recordDidTapPodStickersC2A: (metadata: TrackingParams) =>
        recordUserAction(UserActions.DID_TAP_POD_STICKERS_C2A, metadata),
      recordDidTapPodStickersSheetC2A: (metadata: TrackingParams) =>
        recordUserAction(UserActions.DID_TAP_POD_STICKERS_SHEET_C2A, metadata),
      recordDidSelectPodCategory: (metadata: TrackingParams) =>
        recordUserAction(UserActions.DID_SELECT_POD_CATEGORY, metadata),
      recordDidResumeCart: (metadata: TrackingParams) =>
        recordUserAction(UserActions.DID_RESUME_CART, metadata),
      recordDidTapShoppingCart: (metadata: TrackingParams) =>
        recordUserAction(UserActions.DID_TAP_SHOPPING_CART, metadata),
      recordDidAddProductToCart: (metadata: TrackingParams) =>
        recordUserAction(UserActions.DID_ADD_PRODUCT_TO_CART, metadata),
      recordDidTapToGetToCheckout: (metadata: TrackingParams) =>
        recordUserAction(UserActions.DID_TAP_TO_GET_TO_CHECKOUT, metadata),
      recordDidCompleteCheckout: (metadata: TrackingParams) =>
        recordUserAction(UserActions.DID_COMPLETE_CHECKOUT, {
          ...metadata,
        }),
      recordDidSelectPhotoProduct: (metadata: TrackingParams) =>
        recordUserAction(UserActions.DID_SELECT_PHOTO_PRODUCT, {
          ...metadata,
        }),

      recordDidTapProductPageC2A: (metadata: TrackingParams) =>
        recordUserAction(UserActions.DID_TAP_PRODUCT_PAGE_C2A, {
          ...metadata,
        }),
      recordDidTapPreview: (metadata: TrackingParams) =>
        recordUserAction(UserActions.DID_TAP_PREVIEW, {
          ...metadata,
        }),
      recordDidSelectPodProduct: (metadata: TrackingParams) =>
        recordUserAction(UserActions.DID_SELECT_POD_PRODUCT, metadata),
      recordDidSelectPodTemplate: (metadata: TrackingParams) =>
        recordUserAction(UserActions.DID_SELECT_POD_TEMPLATE, metadata),
      recordDidViewSelectTemplatePage: (metadata: TrackingParams) =>
        recordUserAction(UserActions.DID_VIEW_SELECT_TEMPLATE_PAGE, metadata),
      recordDidSelectPhotos: (metadata: TrackingParams) =>
        recordUserAction(UserActions.DID_SELECT_PHOTOS, metadata),
      recordPreviewSuccess: (metadata: TrackingParams) =>
        recordUserAction(UserActions.POD_PREVIEW_SUCCESS, metadata),
      recordPreviewFailure: (metadata: TrackingParams) =>
        recordUserAction(UserActions.POD_PREVIEW_FAILURE, metadata),
      recordDidTapCartKeepShopping: () => recordUserAction(UserActions.DID_TAP_CART_KEEP_SHOPPING),
      divvyup: {
        recordDidSelectPhotoProduct: (metadata: TrackingParams) =>
          recordUserAction(UserActions.DID_SELECT_PHOTO_PRODUCT, {
            ...metadata,
          }),
      },
    },

    recordFmnProductClick: (metadata: {
      productId: number
      cardLocation: string
      lpVariant?: string
    }) => recordUserAction(UserActions.DID_CLICK_FMN_PRODUCT, metadata),

    recordFmnOfferWallTimeSpent: (metadata: { timeSpentMs: number; platform: 'ios' | 'android' }) =>
      recordUserAction(UserActions.TIME_SPENT_FMN_OFFER_WALL, metadata),

    recordFmnC2ATap: (metadata: {
      productSlug: string
      ctaLocation: string
      productName?: string
    }) => recordUserAction(UserActions.DID_TAP_FMN_C2A, metadata),

    recordDidCopyFmnPromoCode: (metadata: {
      productSlug: string
      promoCode: string
      ctaLocation: string
    }) => recordUserAction(UserActions.DID_COPY_FMN_PROMO_CODE, metadata),

    recordDidTapFmnSeeAll: (metadata: { categorySlug: string }) =>
      recordUserAction(UserActions.DID_TAP_FMN_SEE_ALL, metadata),
  }
}

export default useRecordUserAction
